import { useCallback, useEffect, useMemo } from "react"
import { useHistory } from "react-router-dom"
import { PageMark } from "../constants/pageMark"
import useBoolean from "../hooks/useBoolean"
import { useInput } from "../hooks/useInput"
import { useLatest } from "../hooks/useLatest"
import { useValue } from "../hooks/useValue"
import { createUseContext } from '../lib/createUseContext'
import { createAddress } from "../service/common"
import { getCities, provinceList } from "../utils/provinceList"
import { useRootContext } from "./Root"

export const [PrizeProvider, usePrizeContext] = createUseContext(() => {
  const history = useHistory()
  const { tokenActions } = useRootContext()

  useEffect(() => {
    if (!tokenActions.value) {
      setTimeout(() => {
        alert('请先输入券码')
      }, 100);
      history.goBack()
    }
  }, [tokenActions.value, history])

  const nameInputActions = useInput({ rule: { minLength: 2 } })
  const pickedProvince = useValue(provinceList[0])
  const cities = useMemo(() => getCities(pickedProvince.value), [pickedProvince.value])
  const pickedCity = useValue(cities[0])
  const racedCity = useLatest(useMemo(() => [pickedCity.value, cities[0]],[pickedCity, cities]))
  const addressActions = useInput({ rule: { minLength: 2 } })
  const phoneActions = useInput({ rule: { minLength: 11, maxLength: 11, regexp: /^\w+$/ }, restrict: { maxLength: 11, regexp: /^\d+$/ } })
  const checkedRule = useBoolean(false)
  const inputValid = useMemo(() => checkedRule.value && nameInputActions.valid && addressActions.valid && phoneActions.valid, [checkedRule, nameInputActions, addressActions, phoneActions])

  const handleProvinceChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    pickedProvince.set(e.currentTarget.value)
  }, [pickedProvince])
  const handleCityChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    pickedCity.set(e.currentTarget.value)
  }, [pickedCity])

  const gotoDetail = useCallback(() => { history.push(PageMark.DETAIL) }, [history])
  const gotoFeedback = useCallback(() => { history.replace(PageMark.FEEDBACK) }, [history])
  const gotoQuery = useCallback(() => { history.replace(PageMark.QUERY) }, [history])
  const gotoRule = useCallback(() => { history.push(PageMark.RULE) }, [history])

  const submitCreateAddress = useCallback(async (successHandler: () => void) => {
    if (!tokenActions.value) return

    const addressResponse = await createAddress({
      name: nameInputActions.value,
      province: pickedProvince.value,
      district: racedCity,
      detailAddress: addressActions.value,
      mobile: phoneActions.value,
    }, tokenActions.value)

    if (addressResponse.success) {
      successHandler()
    } else {
      alert(addressResponse.msg)
    }
  }, [tokenActions.value, nameInputActions.value, pickedProvince.value, racedCity, addressActions.value, phoneActions.value])

  const submitRedeem = useCallback(() => {
    submitCreateAddress(() => gotoFeedback())
  }, [gotoFeedback, submitCreateAddress])

  const submitUpdate = useCallback(() => {
    submitCreateAddress(() => {
      alert('修改成功')
      gotoQuery()
    })
  }, [gotoQuery, submitCreateAddress])

  return {
    nameInputProps: nameInputActions.inputProps,
    addressInputProps: addressActions.inputProps,
    phoneInputProps: phoneActions.inputProps,
    province: pickedProvince,
    city: pickedCity,
    provinceList,
    cities,
    inputValid,
    checkedRule,
    handleProvinceChange,
    handleCityChange,
    gotoDetail,
    gotoRule,
    submitRedeem,
    submitUpdate,
  }
})
