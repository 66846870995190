import { useSetState } from "../hooks/useSetState"
import { useValue } from "../hooks/useValue"
import { createUseContext } from '../lib/createUseContext'

export type ExpressInfo = {
  canModify: boolean,
  expressSn: string,
  prizeName: string,
  prizeTime1: string,
  prizeTime2: string,
  statusText: string,
}

export const [RootProvider, useRootContext] = createUseContext(() => {
  const tokenActions = useValue('')

  const expressInfo = useSetState<ExpressInfo>({
    canModify: false,
    expressSn: '',
    prizeName: '',
    prizeTime1: '',
    prizeTime2: '',
    statusText: '',
  })

  return {
    tokenActions,
    expressInfo,
  }
})
