import { useCallback, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { PageMark } from "../constants/pageMark"
import { createUseContext } from '../lib/createUseContext'
import { useRootContext } from "./Root"

export const [QueryProvider, useQueryContext] = createUseContext(() => {
  const history = useHistory()

  const { tokenActions } = useRootContext()

  useEffect(() => {
    if (!tokenActions.value) {
      setTimeout(() => {
        alert('请先输入券码')
      }, 100);
      history.goBack()
    }
  }, [tokenActions.value, history])

  const gotoHome = useCallback(() => { history.goBack() }, [history])
  const gotoModify = useCallback(() => { history.push(PageMark.MODIFY) }, [history])

  return {
    gotoHome,
    gotoModify,
  }
})
