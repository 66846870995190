import { useRef, useEffect, useState, useMemo } from 'react';

const usePreviousArray = <T>(values: T[]): T[] => {
  const [previousValues, setPreviousValues] = useState(values);
  const valuesRef = useRef(values);

  useEffect(() => {
    setPreviousValues(valuesRef.current!);
    valuesRef.current = values;
  }, [values]);

  return previousValues;
}

export const useLatest = <T>(
  values: T[],
  filter: (value: T) => boolean = () => true,
): T => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedValues = useMemo(() => values, values)
  const latestRef = useRef(memoizedValues[0]);
  const previousValues = usePreviousArray(memoizedValues);

  return useMemo(
    () => memoizedValues.find((v, i) => v !== previousValues[i] && filter(v)) ?? latestRef.current,
    [filter, previousValues, memoizedValues],
  )
}
