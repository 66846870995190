import React from 'react'
import { Page } from '../components/Page';
import { Image } from '../components/Image';
import { Content } from '../components/Content';
import { Button } from '../components/Button';
import page6 from '../assets/page6.jpg'
import styled from 'styled-components'
import { px2vw } from '../utils/px2vw';
import { LayoutRow } from '../components/LayoutRow';
import { useQueryContext } from '../context/Query';
import { useRootContext } from '../context/Root';

const TopOffset = 426

const FormRow = styled.div`
  display: flex;
  position: absolute;
  top: ${px2vw(TopOffset - 184)};
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${px2vw(40)};
  padding-right: 0;
  padding-top: ${px2vw(20)};
  padding-bottom: ${px2vw(40)};
  font-size: ${px2vw(20)};
  line-height: ${px2vw(26)};
  width: ${px2vw(670)};
  box-sizing: border-box;
`
const ModifyButton = styled.span`
  text-decoration: underline;
`

const FormColumn = styled.div`
  flex-shrink: 0;
  overflow: hidden;
  color: #3a4664;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  box-sizing: border-box;

  &.first {
    flex-basis: 34%;
    padding-right: 1em;
  }
  &.second {
    flex-basis: 36%;
    padding-right: 1em;
  }
  &.third {
    flex-basis: 30%;
  }
`

export const QueryPage = () => {
  const { expressInfo } = useRootContext()
  const { gotoModify, gotoHome } = useQueryContext()

  return (
    <Page>
      <Image src={page6} alt="兑换奖品展示" />
      <Content>
        <FormRow>
          <FormColumn className="first">
            <div>{expressInfo.value.prizeTime1}</div>
            <div>{expressInfo.value.prizeTime2}</div>
          </FormColumn>
          <FormColumn className="second">{expressInfo.value.prizeName}</FormColumn>
          <FormColumn className="third">
            <div>{expressInfo.value.statusText}</div>
            <div>{expressInfo.value.canModify
              ? (
                <ModifyButton onClick={gotoModify}>修改发货信息</ModifyButton>
              )
              : expressInfo.value.expressSn}
            </div>
          </FormColumn>
        </FormRow>
        <LayoutRow top={TopOffset + 0} height={72} space="center">
        <Button variant="normal" onClick={gotoHome} width={220} height={60}>返回</Button>
      </LayoutRow>
      </Content>
    </Page>
  )
}

