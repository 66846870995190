import React from 'react'
import { Page } from '../components/Page';
import { Image } from '../components/Image';
import page5 from '../assets/page5.jpg'

export const FeedbackPage = () => {
  return (
    <Page>
      <Image src={page5} alt="您的礼品已兑换成功" />
    </Page>
  )
}

