import styled from 'styled-components'
import { bothCenter } from '../style/common'
import { px2vw } from '../utils/px2vw'
import checkImage from '../assets/check.png'

export const Check = styled.div<{ width: number, height?: number, checked: boolean }>`
  ${bothCenter}

  background-image: ${({ checked }) => checked ? `url(${checkImage})` : null};
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center;
  padding: ${px2vw(8)};
  width: ${({ width }) => px2vw(width)};
  height: ${({ height }) => height ? px2vw(height) : '100%'};
  box-sizing: border-box;
`
