import React from 'react'
import {
  HashRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'
import { GlobalStyle } from './components/GlobalStyle'
import { HomePage } from './pages/Home'
import { QueryPage } from './pages/Query'
import { FeedbackPage } from './pages/Feedback'
import { PrizePage } from './pages/Prize'
import { PageMark } from './constants/pageMark'
import { RootProvider } from './context/Root'
import { HomeProvider } from './context/Home'
import { QueryProvider } from './context/Query'
import { PrizeProvider } from './context/Prize'


const App = () => (
  <Router>
    <GlobalStyle />
    <RootProvider>
      <Switch>
        <Route exact path={PageMark.HOME}>
          <HomeProvider>
            <HomePage />
          </HomeProvider>
        </Route>
        <Route path={PageMark.PRIZE}>
          <PrizeProvider>
            <Switch>
              <Route exact path={PageMark.PRIZE}>
                <PrizePage />
              </Route>
              <Route path={PageMark.MODIFY}>
                <PrizePage isUpdateMode />
              </Route>
              <Route path={PageMark.FEEDBACK}>
                <FeedbackPage />
              </Route>
            </Switch>
          </PrizeProvider>
        </Route>
        <Route path={PageMark.QUERY}>
          <QueryProvider>
            <QueryPage />
          </QueryProvider>
        </Route>
      </Switch>
    </RootProvider>
  </Router>
)
export default App
