import React from 'react'
import styled from "styled-components"
import { px2vw } from "../utils/px2vw"
import { fullSize, verticalCenter } from '../style/common'
import { SelectProps } from '../type/common'

const SelectWrapper = styled.div<{ width?: number, height?: number, }>`
  position: relative;
  width: ${({ width }) => width ? px2vw(width) : '100%'};
  height: ${({ height }) => height ? px2vw(height) : '100%'};
  background: transparent;
  box-sizing: border-box;
  border-radius: ${px2vw(10)};

  select {
    ${verticalCenter}
    ${fullSize}
    background: transparent;
    border: none;
    outline: none;
    padding-left: ${px2vw(20)};
    padding-right: ${px2vw(56)};
    font-size: ${px2vw(24)};
    appearance: none;
  }
`

export const Select = ({ options, width, height, ...selectProps}: SelectProps & { width?: number, height?: number, options: string[] }) => {
  return (
    <SelectWrapper width={width} height={height}>
      <select {...selectProps}>
        {options.map(opt => (
          <option key={opt}>{opt}</option>
        ))}
      </select>
    </SelectWrapper>
  )
}
