import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { PageMark } from "../constants/pageMark"
import { useInput } from "../hooks/useInput"
import { createUseContext } from '../lib/createUseContext'
import { getCouponInfo, getTipAlert } from "../service/common"
import { useRootContext } from "./Root"

export const [HomeProvider, useHomeContext] = createUseContext(() => {
  const { tokenActions, expressInfo } = useRootContext()

  const history = useHistory()

  const couponInputActions = useInput({ rule: { maxLength: 10, minLength: 10, regexp: /^\d+$/ }, restrict: { maxLength: 10, regexp: /^\d+$/ } })
  const codeActions = useInput({ rule: { maxLength: 8, minLength: 8, regexp: /^\w+$/ }, restrict: { maxLength: 8, regexp: /^\w*$/ } })
  const inputValid = useMemo(() => couponInputActions.valid && codeActions.valid, [couponInputActions, codeActions])

  const validateCouponInfo = useCallback(async (mustUnFilled: boolean, successHandler: () => void) => {
    const couponInfoResponse = await getCouponInfo(couponInputActions.value, codeActions.value, mustUnFilled)

    if (couponInfoResponse.success) {
      const { token, ...restCouponInfoResponse } = couponInfoResponse.data
      tokenActions.set(token)
      if (restCouponInfoResponse.withCoupon) {
        const { withCoupon, ...restExpressInfo } = restCouponInfoResponse
        expressInfo.set(restExpressInfo)
      }
      successHandler()
    } else {
      alert(couponInfoResponse.msg)
    }
  }, [couponInputActions.value, expressInfo, codeActions.value, tokenActions])

  const [alertContent, setAlertContent] = useState('')

  useEffect(
    () => {
      getTipAlert().then(response => {
        if (response.success) {
          setAlertContent(response.data)
        }
      })
    },
    []
  )

  const [showAlert, setShowAlert] = useState(false)

  const checkBeforeSubmitLogin = useCallback(
    () => {
      setShowAlert(true)
    },
    []
  )

  const submitLogin = useCallback(
    () => {
      setShowAlert(false)

      return validateCouponInfo(true, () => {
        history.push(PageMark.PRIZE)
      })
    },
    [history, validateCouponInfo]
  )
  const submitQuery = useCallback(
    () => validateCouponInfo(false, () => {
      history.push(PageMark.QUERY)
    }),
    [history, validateCouponInfo]
  )

  return {
    couponInputProps: couponInputActions.inputProps,
    passwordInputProps: codeActions.inputProps,
    inputValid,
    showAlert,
    checkBeforeSubmitLogin,
    submitLogin,
    submitQuery,
    alertContent,
  }
})
