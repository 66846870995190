import React from 'react'
import { Page } from '../components/Page';
import { Image } from '../components/Image';
import page2 from '../assets/page2.jpg'
import { LayoutRow } from '../components/LayoutRow';
import { Content } from '../components/Content';
import { Input } from '../components/Input';
import { Button } from '../components/Button';
import { Check } from '../components/Check';
import { Select } from '../components/Select';
import { usePrizeContext } from '../context/Prize';

type Props = {
  isUpdateMode?: boolean,
}

const TopOffset = -515
const LeftOffset = -94

export const PrizePage = ({ isUpdateMode = false }: Props) => {
  const {
    nameInputProps, addressInputProps, phoneInputProps,
    provinceList, cities, province, city,
    handleCityChange, handleProvinceChange, checkedRule,
    inputValid, gotoRule,
    submitRedeem, submitUpdate,
   } = usePrizeContext()

  return (
    <Page>
      <Image src={page2} alt="兑换奖品展示" />
      <Content>
        <LayoutRow top={768 + TopOffset} height={72} paddingLeft={162 + LeftOffset} paddingRight={20}>
          <Input
            {...nameInputProps}
            placeholder="请输入姓名（一个字以上）"
          />
        </LayoutRow>
        <LayoutRow top={892 + TopOffset} height={72} paddingLeft={162 + LeftOffset} paddingRight={36} space="space-between">
          <Select width={180} value={province.value} onChange={handleProvinceChange} options={provinceList} />
          <Select width={180} value={city.value} onChange={handleCityChange} options={cities} />
        </LayoutRow>
        <LayoutRow top={1016 + TopOffset} height={72} paddingLeft={162 + LeftOffset} paddingRight={20}>
          <Input
            {...addressInputProps}
            placeholder="请输入详细地址"
          />
        </LayoutRow>
        <LayoutRow top={1136 + TopOffset} height={72} paddingLeft={162 + LeftOffset} paddingRight={20}>
          <Input
            {...phoneInputProps}
            placeholder="请输入联系电话"
            pattern="[\d]{11}"
            title="11位数字电话"
          />
        </LayoutRow>
        <LayoutRow top={1254 + TopOffset} height={52} paddingLeft={98 + LeftOffset} space="start">
          <Check
            checked={checkedRule.value}
            width={52}
            onClick={checkedRule.toggle}
          />
        </LayoutRow>
        <LayoutRow top={1338 + TopOffset} height={80} space="center">
          <Button
            width={264}
            variant={inputValid ? 'active' : 'disable'}
            onClick={isUpdateMode ? submitUpdate : submitRedeem}
          >{isUpdateMode ? '确认修改' : '确认兑换'}</Button>
        </LayoutRow>
        <LayoutRow top={1466 + TopOffset} height={36} space="center">
          <Button
            width={260}
            variant={'ghost'}
            onClick={gotoRule}
          />
        </LayoutRow>
      </Content>
    </Page>
  )
}

