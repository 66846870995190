export enum CouponSendStatus {
    ToSend = '0',
    Sending = '1',
    Sent = '2',
}

export const getSendStatusText = (status: CouponSendStatus) => {
    switch (status) {
        case CouponSendStatus.ToSend:
            return '待发货'
        case CouponSendStatus.Sending:
            return '发货中'
        case CouponSendStatus.Sent:
            return '已发货'
        default:
            return ''
    }
}
