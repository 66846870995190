import React from 'react'
import { Page } from '../components/Page';
import { Image } from '../components/Image';
import page1 from '../assets/page1.jpg'
import { Content } from '../components/Content';
import { LayoutRow } from '../components/LayoutRow';
import { Input } from '../components/Input';
import { Button } from '../components/Button';
import { Modal } from '../components/Modal';
import { useHomeContext } from '../context/Home';

const heightOffset = -32

export const HomePage = () => {
  const { couponInputProps, passwordInputProps, inputValid, showAlert, checkBeforeSubmitLogin, submitLogin, submitQuery, alertContent } = useHomeContext()

  return (
    <Page>
      <Image src={page1} alt="兑换奖品展示" />
      <Content>
        <LayoutRow top={714 + heightOffset} height={72}>
          <Input
            {...couponInputProps}
            placeholder="请输入券码"
            pattern="[\d]{10}"
            title="10位数字券码"
            darkColor
          />
        </LayoutRow>
        <LayoutRow top={836 + heightOffset} height={72}>
          <Input
            {...passwordInputProps}
            placeholder="请输入密码"
            pattern="[\w]{11}"
            title="8位数字或英文密码"
            darkColor
          />
        </LayoutRow>
        <LayoutRow width={700} paddingLeft={0} paddingRight={0} top={1094 + heightOffset} height={88} space="space-between">
          <Button
            width={340}
            variant={inputValid ? 'active' : 'disable'}
            onClick={alertContent ? checkBeforeSubmitLogin : submitLogin}
          >兑换奖品</Button>
          <Button
            width={340}
            variant={inputValid ? 'active' : 'disable'}
            onClick={submitQuery}
          >兑换查询</Button>
        </LayoutRow>
      </Content>
      <Modal
        visible={showAlert && !!alertContent}
        Button={<Button width={340} variant="active" onClick={submitLogin}>确认</Button>}>
        {alertContent}
      </Modal>
    </Page>
  )
}

