import unfetch from "unfetch"
import { formatTime } from "../utils/formatTime"
import { getSendStatusText, CouponSendStatus } from "../constants/couponStatus"

type Success<T> = {
  success: true,
  data: T
}

type Failure = {
  success: false,
  msg: string,
}

type Result<T> = Success<T> | Failure

// const serverHost = window.location.host
const serverHost = window.location.host.replace(/^[\w]+/, ($0) => $0 + '-admin')

const EndPoints = {
  TipAlert: `//${serverHost}/api/index`,
  Login: `//${serverHost}/api/index/login`,
  CreateAddress: `//${serverHost}/api/index/create_address`,
  GetCouponStatus: `//${serverHost}/api/index/get_coupon_status`,
}

export const getTipAlert = async (): Promise<Result<string>> => {
  const response = await unfetch(EndPoints.TipAlert, {
    method: 'GET',
  }).catch(e => null)

  if (!response) {
    return { success: true, data: '' }
  }

  type ResponseInfo = {
    code: 0 | 1,
    msg: string,
    data: { content: string }
  }

  const { code, data }: ResponseInfo = await response.json().catch(() => ({ code: 0, msg: '请求错误，请稍后再试' }))

  if (code !== 1) {
    return {
      success: true,
      data: '',
    }
  }

  return {
    success: true,
    data: data.content,
  }
}

export const login = async (card: string, couponCode: string): Promise<Result<string>> => {
  const formData = new FormData();
  formData.append('card', card);
  formData.append('code', couponCode);

  const response = await unfetch(EndPoints.Login, {
    method: 'POST',
    body: formData,
  }).catch(e => null)

  if (!response) {
    return { success: false, msg: '网络开小差，请稍后再试' }
  }

  type ResponseInfo = {
    code: 0 | 1,
    msg: string,
    data: { token: string }
  }


  const { code, msg, data }: ResponseInfo = await response.json().catch(() => ({ code: 0, msg: '请求错误，请稍后再试' }))

  if (code !== 1) {
    return {
      success: false,
      msg,
    }
  }

  return {
    success: true,
    data: data.token,
  }
}

type CreateAddressInfo = {
  name: string,
  province: string,
  district: string,
  detailAddress: string,
  mobile: string,
}

export const createAddress = async ({ name, province, district, detailAddress, mobile }: CreateAddressInfo, token: string): Promise<Result<null>> => {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('province', province);
  formData.append('district', district);
  formData.append('detail_address', detailAddress);
  formData.append('mobile', mobile);

  const response = await unfetch(EndPoints.CreateAddress + `?token=${token}`, {
    method: 'POST',
    body: formData,
  }).catch(e => null)

  if (!response) {
    return { success: false, msg: '网络开小差，请稍后再试' }
  }

  type ResponseInfo = {
    code: 0 | 1,
    msg: string,
  }

  const { code, msg }: ResponseInfo = await response.json().catch(() => ({ code: 0, msg: '请求错误，请稍后再试' }))

  if (code !== 1) {
    return {
      success: false,
      msg,
    }
  }

  return {
    success: true,
    data: null,
  }
}

type CouponStatus = {
  prizeTime1: string,
  prizeTime2: string,
  prizeName: string,
  statusText: string,
  canModify: boolean,
  expressSn: string,
}

export const getCouponStatus = async (token: string, mustUnFilled: boolean): Promise<Result<CouponStatus | null>> => {
  const response = await unfetch(EndPoints.GetCouponStatus + `?token=${token}`, {
    method: 'GET'
  }).catch(e => null)

  if (!response) {
    return { success: false, msg: '网络开小差，请稍后再试' }
  }

  type ResponseInfo = {
    code: -1 | 0 | 1,
    msg: string,
    data: { status: CouponSendStatus, prize_time: number, express_sn: null | number }
  }

  const { code, msg, data }: ResponseInfo = await response.json().catch(() => ({ code: 0, msg: '请求错误，请稍后再试' }))

  if (mustUnFilled) {
    if (code !== -1) {
      return {
        success: false,
        msg: '该券码已被兑换',
      }
    }

    return {
      success: true,
      data: null,
    }
  }

  if (code !== 1) {
    return {
      success: false,
      msg,
    }
  }

  const { dateText, timeText } = formatTime(data.prize_time * 1000)

  return {
    success: true,
    data: {
      prizeTime1: dateText,
      prizeTime2: timeText,
      prizeName: '千里共婵娟月饼礼盒一套',
      statusText: getSendStatusText(data.status),
      expressSn: (data.status === CouponSendStatus.Sent && data.express_sn) ? `单号${data.express_sn}` : '',
      canModify: data.status === CouponSendStatus.ToSend,
    }
  }
}


type CouponInfo = (CouponStatus & {
  withCoupon: true,
  token: string,
}) | { withCoupon: false, token: string }

export const getCouponInfo = async (card: string, couponCode: string, mustUnFilled: boolean): Promise<Result<CouponInfo>> => {
  const loginResponse = await login(card, couponCode)

  if (!loginResponse.success) {
    return {
      success: false,
      msg: loginResponse.msg
    }
  }

  const token = loginResponse.data

  const couponStatusResponse = await getCouponStatus(token, mustUnFilled)

  if (!couponStatusResponse.success) {
    return {
      success: false,
      msg: couponStatusResponse.msg,
    }
  }

  return {
    success: true,
    data: couponStatusResponse.data ? {
      ...couponStatusResponse.data,
      withCoupon: true,
      token,
    } : {
      withCoupon: false,
      token,
    }
  }
}
