import provincesJSON from '../data/provinces.json'

type Province = {
  name: string,
  cities: string[]
}

const provinces: Province[] = provincesJSON

export const getCities = (provinceName: string) => provinces.find(province => province.name === provinceName)?.cities ?? ['-']

export const provinceList = provinces.map(province => province.name)

