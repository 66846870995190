export const formatTime = (time: number) => {
  const date = new Date(time)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  const dateText = `${year}年${month}月${day}日`
  const timeText = `${('0' + hours.toString()).slice(-2)}:${('0' + minutes.toString()).slice(-2)}:${('0' + seconds.toString()).slice(-2)}`

  return {
    dateText,
    timeText,
  }
}
